<template>
  <div class="jh-container">
    <div class="jh-ui-header">
        <h1>상담사 권한관리</h1>
        <div class="is-right">
        </div>
    </div>
    <div class="jh-card">
      <div class="jh-card-header has-tab">
        <v-tabs v-model="tab1" slider-size="4">
          <v-tab key="tab1-1">권한그룹별 상담사 설정</v-tab>
          <v-tab key="tab1-2">상담사별 권한 관리</v-tab>
        </v-tabs>
      </div>
      <div class="jh-card-body">
        <v-tabs-items v-model="tab1">
          <!--tab1-1-->
          <v-tab-item key="tab1-1">
            <div class="tab-contnets">
              <div class="jh-search-form">
                <table>
                  <colgroup>
                    <col width="55px">
                    <col width="120px">
                    <col width="60px">
                    <col width="120px">
                    <col width="80px">
                    <col width="120px">
                    <col>
                  </colgroup>
                  <tr>
                    <th><label>권한그룹</label></th>
                    <td>
                      <v-select class="jh-form" :items="dropItems" item-text="CD_NM" item-value="CD" placeholder="선택" @change="getGroupList" v-model="atrtgroupId"></v-select>
                    </td>
                    <th><label>사번</label></th>
                    <td>
                      <v-text-field class="jh-form" name="name" v-model="tab_userId"></v-text-field>
                    </td>
                    <th><label>상담사명</label></th>
                    <td>
                      <v-text-field class="jh-form" name="name" v-model="userNm"></v-text-field>
                    </td>
                    <td class="has-search"><v-btn class="jh-btn is-search" @click="getGroupList">조회</v-btn></td>
                  </tr>
                </table>
              </div>
              <div class="jh-cols">
                <div>
                  <div class="jh-ui-header">
                    <h2>미할당상담사</h2>
                  </div>
                  <v-data-table
                  class="jh-grid has-control"
                  height="560px"
                  :headers="divGridAuthUserNoAlloc"
                  :items="divGridAuthUserNoAllocDataText"
                  :items-per-page="30"
                  item-key="USER_ID"
                  :page.sync="divGridAuthUserNoAllocPage"
                  show-select
                  fixed-header
                  hide-default-footer
                  @page-count="divGridAuthUserNoAllocPageCount = $event"
                  no-data-text="검색된 결과가 없습니다."
                  v-model="selectedDivGridAuthUserNoAllocList"
                  :item-class="isActiveRow1"
                  @click:row="getGrid1ListSel"
                  ></v-data-table>
                  <div class="jh-pager">
                    <v-pagination v-model="divGridAuthUserNoAllocPage" :length="divGridAuthUserNoAllocPageCount" :total-visible="divGridAuthUserNoAllocTotalVisible"></v-pagination>
                    <span class="jh-counter is-left">총 <em>{{ divGridAuthUserNoAllocDataText.length }}</em> 건</span>
                  </div>                  
                  <!-- <data-tables
                    v-model="selectedDivGridAuthUserNoAllocList"
                    dataTableClass="has-control"
                    :data-table-options="dataTableOptions"
                    @click:row="getGrid1ListSel"
                    :paginationOptions="paginationOptions"
                  ></data-tables> -->
                </div>
                <div class="is-col-center">                       
                  <v-btn class="jh-btn is-move is-md" v-if="this.mixin_set_btn(this.$options.name, 'btnAddArrow1')" @click="moveRight_tab1"><i class="jh-icon-move-right"></i></v-btn>
                  <v-btn class="jh-btn is-move is-md" v-if="this.mixin_set_btn(this.$options.name, 'btnDelArrow1')" @click="moveLeft_tab1"><i class="jh-icon-move-left"></i></v-btn>
                </div>
                <div>
                  <div class="jh-ui-header">
                    <h2>할당상담사</h2>
                    <div class="is-right">
                      <!--추가하였음-->
                      <v-btn class="jh-btn is-main" @click="authUserSave" v-if="this.mixin_set_btn(this.$options.name, 'btnSave2')">저장</v-btn>
                    </div>
                  </div>
                  <v-data-table
                  class="jh-grid has-control"
                  height="560px"
                  :headers="divGridAuthUserAlloc"
                  :items="divGridAuthUserAllocDataText"
                  :items-per-page="30"
                  item-key="USER_ID"
                  :page.sync="page"
                  show-select
                  fixed-header
                  hide-default-footer
                  @page-count="pageCount = $event"
                  no-data-text="검색된 결과가 없습니다."
                  v-model="selectedDivGridAuthUserAllocList"
                  :item-class="isActiveRow1"
                  v-on:click:row="getGrid1ListSel"
                  ></v-data-table>
                  <div class="jh-pager">
                    <span class="jh-counter is-left">총 <em>{{ divGridAuthUserAllocDataText.length }}</em> 건</span>
                  </div>               
                  <!-- <data-tables
                    v-model="selectedDivGridAuthUserAllocList"
                    dataTableClass="has-control"
                    :data-table-options="dataTableOptions2"
                    @click:row="getGrid1ListSel"
                    :paginationOptions="paginationOptions"
                  ></data-tables> -->
                </div>
              </div>
            </div>
          </v-tab-item>
          <!--//tab1-1-->
          
          <!--tab1-2-->
          <v-tab-item key="tab1-2">
            <div class="tab-contnets">
                
              <div class="jh-search-form">
                <table>
                  <colgroup>
                    <col width="30px">
                    <col width="120px">
                    <col width="80px">
                    <col width="150px">
                    <col>
                  </colgroup>
                  <tr>
                    <th><label>사번</label></th>
                    <td>
                      <v-text-field class="jh-form" name="name" v-model="tab2_userId"></v-text-field>
                    </td>
                    <th><label>상담사명</label></th>
                    <td>
                      <div class="jh-cols">
                        <v-text-field class="jh-form" name="name" v-model="tab2_userNm"></v-text-field>
                        <v-btn class="jh-btn has-icon-only is-fill" @click="btnUser"><i class="jh-icon-search is-white"></i></v-btn>
                        <v-btn class="jh-btn has-icon-only" @click="initUser"><i class="jh-icon-refresh"></i></v-btn>
                      </div>
                    </td>
                    <td class="has-search"><v-btn class="jh-btn is-search" @click="getList2OrNot">조회</v-btn></td>
                  </tr>
                </table>
              </div>
              <div class="jh-cols">
                <div class="is-col-fix" style="width: 400px;">
                  <div class="jh-ui-header"><h2>미할당그룹</h2></div>
                  <v-data-table
                  class="jh-grid has-control"
                  height="560px"
                  :headers="divGridAuthGroupNoAlloc"
                  :items="divGridAuthGroupNoAllocDataText"
                  :items-per-page="30"
                  item-key="ATRT_GROUP_ID"
                  :page.sync="page"
                  show-select
                  fixed-header
                  hide-default-footer
                  @page-count="pageCount = $event"
                  no-data-text="검색된 결과가 없습니다."
                  v-model="selectedDivGridAuthGroupNoAllocList"
                  :item-class="isActiveRow2"
                  @click:row="getGrid1ListSel"
                  ></v-data-table>
                  <div class="jh-pager">
                    <span class="jh-counter is-left">총 <em>{{ divGridAuthGroupNoAllocDataText.length }}</em> 건</span>
                  </div>                              
                  <!-- <data-tables
                    v-model="selectedDivGridAuthGroupNoAllocList"
                    dataTableClass="has-control"
                    :data-table-options="dataTableOptions3"
                    @click:row="getGrid1ListSel"
                    :paginationOptions="paginationOptions"
                  ></data-tables> -->
                </div>
                <div class="is-col-center">                          
                  <v-btn class="jh-btn is-move is-md" v-if="this.mixin_set_btn(this.$options.name, 'btnAddArrow1')" @click="moveRight_tab2"><i class="jh-icon-move-right"></i></v-btn>
                  <v-btn class="jh-btn is-move is-md" v-if="this.mixin_set_btn(this.$options.name, 'btnDelArrow1')" @click="moveLeft_tab2"><i class="jh-icon-move-left"></i></v-btn>
                </div>
                <div>
                  <div class="jh-ui-header">
                    <h2>할당그룹</h2>
                    <div class="is-right">
                      <v-btn class="jh-btn is-main" id="btnSave_user" @click="submitGroupConfirm" v-if="this.mixin_set_btn(this.$options.name, 'btnSave1')">저장</v-btn>
                    </div>
                  </div>
                  <v-data-table
                  class="jh-grid has-control"
                  height="560px"
                  :headers="divGridAuthGroupAlloc"
                  :items="divGridAuthGroupAllocDataText"
                  :items-per-page="30"
                  item-key="ATRT_GROUP_ID"
                  :page.sync="page"
                  show-select
                  fixed-header
                  hide-default-footer
                  @page-count="pageCount = $event"
                  no-data-text="검색된 결과가 없습니다."
                  v-model="selectedDivGridAuthGroupAllocList"
                  :item-class="isActiveRow2"
                  @click:row="getGrid1ListSel"
                  >
                  </v-data-table>
                  <div class="jh-pager">
                    <v-pagination v-model="page" :length="pageCount" :total-visible="totalVisible"></v-pagination>
                    <span class="jh-counter is-left">총 <em>{{ divGridAuthGroupAllocDataText.length }}</em> 건</span>
                  </div>                           
                  <!-- <data-tables
                    v-model="selectedDivGridAuthGroupAllocList"
                    dataTableClass="has-control"
                    :data-table-options="dataTableOptions4"
                    @click:row="getGrid1ListSel"
                    :paginationOptions="paginationOptions"
                  ></data-tables> -->
                </div>
              </div>
            </div>
          </v-tab-item>
          <!--tab1-2-->
        </v-tabs-items>          
      </div>
      <v-dialog max-width="1000" v-model="DialogP101801">
        <dialog-P101801
          headerTitle="고객정보"
          @hide="hideDialog('P101801')"
          :userInfo="userInfo"
          @input="userInfo = $event"
          @selectUser="[hideDialog('P101801'), setUser()]"
        ></dialog-P101801>
        <template v-slot:body></template>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import dataTables from "@/components/DataTables";
/*import DialogM810401P01 from "./M810401P01.vue";*/
import api from "@/store/apiUtil";
import DialogP101801 from "./P101801.vue";
import {mixin} from "@/mixin/mixin";
export default {
  name: "MENU_E040107", //name은 'MENU_' + 파일명 조합
  components: {
    DialogP101801,
    dataTables
  },
  mixins:[mixin],
  data() {
    return {
      tab1: 'tab1-1',
      DialogP101801:false,
      HEADERResult:'',
      DATAResult:'',
      HEADER_SERVICE:'',
      HEADER_TYPE:'BIZ_SERVICE',
      HEADER_GRID_ID:'',
      HEADER_ROW_CNT:'',
      HEADER_PAGES_CNT:'',
      userInfo: {},
      headers:{},
      sendData: {},
      URLData:'',
      userNick:'',
      userNm:'',
      ASP_CUST_KEY:'',
      aspCustKey:'',
      tab_userId:'',
      tab2_userId:'',
      tab2_userNm:'',
      tab2_userNick:'',
      regDttm:'',
      atrtgroupId:'',
      atrtgroupNm:'',
      nullAble:'',
      dataFlag:'',
      userAttra:'',
      userAttrb:'',
      userAttrc:'',
      userAttrd:'',
      mngrYn:'',
      etcInfo01:'',
      etcInfo02:'',
      etcInfo02Nm:'',
      groupXpln:'',
      ASP_NEWCUST_KEY:'',
      custItems:[],

      dialogM810401P01: false,
      dialogM810401P02: false,
      dialogM810401P03: false,
      menu1: false,
      menu2: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
      ],
      radioGroup: [
        {
          text: "상담사명",
        },
        {
          text: "최근접속일시",
        },
      ],
      radioGroupSelected: "상담사명",
      dropItems: [],
      dropItems2: [],
      divGridAuthUserNoAlloc: [
        { text: "사번", value: "USER_ID", },
        { text: "상담사명", value: "USER_NM", },
        // { text: "상담사닉네임", value: "USER_NICK", },
        { text: "등록일시", value: "REG_DTTM", align: "center", },
      ],
      divGridAuthUserAlloc: [
        { text: "사번", value: "USER_ID", },
        { text: "상담사명", value: "USER_NM", },
        // { text: "상담사닉네임", value: "USER_NICK", },
        { text: "등록일시", value: "REG_DTTM", align: "center", },
      ],
      divGridAuthUserNoAllocDataText: [],
      divGridAuthUserNoAllocPage: 1,
      divGridAuthUserNoAllocPageCount: 0,
      divGridAuthUserNoAllocItemsPerPage: 100,
      divGridAuthUserNoAllocTotalVisible: 10,
      divGridAuthUserAllocDataText:[],
      selectedDivGridAuthUserNoAllocList:[],
      selectedDivGridAuthUserAllocList:[],
      divGridAuthGroupNoAlloc:[
        { text: "그룹명", value: "ATRT_GROUP_NM", align: "center", },
        { text: "상담사구분", value: "ETC_INFO02_NM", align: "center", },
      ],
      divGridAuthGroupAlloc:[
        { text: "그룹명", value: "ATRT_GROUP_NM", align: "center", },
        { text: "그룹정보", value: "GROUP_XPLN", align: "center", },
        { text: "관리자여부", value: "MNGR_YN", align: "center", },
        { text: "우선순위", value: "ETC_INFO01", align: "center", },
        { text: "상담사구분", value: "ETC_INFO02_NM", align: "center", },
        { text: "기타03", value: "ETC_INFO03", align: "center", },
      ],
      detailData: {},
      divGridAuthGroupNoAllocDataText:[],
      divGridAuthGroupAllocDataText:[],
      selectedDivGridAuthGroupAllocList:[],
      selectedDivGridAuthGroupNoAllocList:[],
      page: 1,
      pageCount: 0,
      itemsPerPage: 100,
      totalVisible: 10,
      textareaRules: [(v) => v.length <= 200 || "Max 200 characters"],
      textareaValue: "200자 제한!",

      dataTableOptions: {
        fixedHeader: true,
        hideDefaultFooter: true,
        height: '560px',
        itemKey: 'USER_ID',
        itemsPerPage: 50,
        noDataText: "조회된 결과가 없습니다.",
        page: 1,
        showSelect: true,
        itemClass: 'isActiveRow1'
      },
      dataTableOptions2: {
        fixedHeader: true,
        hideDefaultFooter: true,
        height: '560px',
        itemKey: 'USER_ID',
        itemsPerPage: 50,
        noDataText: "조회된 결과가 없습니다.",
        page: 1,
        showSelect: true,
        itemClass: 'isActiveRow1'
      },
      dataTableOptions3: {
        fixedHeader: true,
        hideDefaultFooter: true,
        height: '560px',
        itemKey: 'ATRT_GROUP_ID',
        itemsPerPage: 50,
        noDataText: "조회된 결과가 없습니다.",
        page: 1,
        showSelect: true,
        itemClass: 'isActiveRow2'
      },
      dataTableOptions4: {
        fixedHeader: true,
        hideDefaultFooter: true,
        height: '560px',
        itemKey: 'ATRT_GROUP_ID',
        itemsPerPage: 50,
        noDataText: "조회된 결과가 없습니다.",
        page: 1,
        showSelect: true,
        itemClass: 'isActiveRow2'
      },
      paginationOptions: {
          totalVisible: 10
      },
    };
  },
  methods: {

    groupCodeInqire(){
      this.headers = this.initHeaders;
      this.URLData = "/api/setting/system/menu-author-manage/author-group-code/inqire";
      this.headers["SERVICE"] = "setting.system.menu-author-manage.author-group-code";
      this.headers["METHOD"] = "inqire";
      this.headers["ASYNC"] = false;
      this.result();
    },
    groupCodeInqireCallBack(response){
      let header = response.HEADER;
      let data = response.DATA;
      this.dropItems = data;
    },

    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },
    submitDialog(type) {
      console.log("완료");
      this[`dialog${type}`] = false;
    },
    resetDialog() {
      console.log("reset");
    },

    unlockDialog() {
      console.log("unlock");
    },
    initUser(){
      this.tab2_userId = '';
      this.tab2_userNm = '';
      this.tab2_userNick = '';
    },
    showDialog(menu) {
      this[`Dialog${menu}`] = true;

    },
    hideDialog(menu) {
      this[`Dialog${menu}`] = false;
    },

    btnUser(){
      var user = {ASP_CUST_KEY:this.ASP_CUST_KEY, NO_CUST_KEY:'false', USER_ID: this.tab2_userId , USER_NM: this.tab2_userNm};
      this.userInfo = user;
      this.showDialog('P101801');
    },
    setUser(){
      this.tab2_userId = this.userInfo.USER_ID;
      this.tab2_userNm = this.userInfo.USER_NM;
      this.tab2_userNick = this.userInfo.USER_NICK;
    },
    getGrid1ListSel(item){
      this.detailData = JSON.parse(JSON.stringify(item));
    },
    isActiveRow1(item){
      return item.USER_ID== this.detailData.USER_ID? 'is-active':'';
    },
    isActiveRow2(item){
      return item.ATRT_GROUP_ID== this.detailData.ATRT_GROUP_ID? 'is-active':'';
    },
    getList2OrNot(){
      if(this.mixin_isEmpty(this.tab2_userId)) {
        this.common_alert('상담사 아이디를 입력해 주세요.', 'error');
      }else {
        this.getGroupList2();
      }
    },
    getGroupList2(){// 상담사별 권한관리 tab
      this.getDivGridAuthGroupAlloc(); //미할당그룹
      this.getDivGridAuthGroupNoAlloc(); //할당 그룹
    },
    getDivGridAuthGroupAlloc(){
      this.headers = this.initHeaders;
      this.URLData = "/api/setting/system/menu-author-manage/asgn-info-by-agent/inqire";
      this.headers["SERVICE"] ="setting.system.menu-author-manage.asgn-info-by-agent";
      this.headers["METHOD"] = "inqire";
      this.headers["ASYNC"] = false;
      this.headers["GRID_ID"] = "divGridAuthGroupAlloc";

      this.sendData = {};
      this.sendData["USER_ID"] = this.tab2_userId;
      this.sendData["USER_NM"] = this.tab2_userNm;
      this.sendData["USER_NICK"] = this.tab2_userNick;
      this.sendData["NULLABLE"] = "IS NOT NULL";
      this.result();
    },
    getDivGridAuthGroupNoAlloc(){
      this.headers = this.initHeaders;
      this.URLData = "/api/setting/system/menu-author-manage/asgn-info-by-agent/inqire";
      this.headers["SERVICE"] ="setting.system.menu-author-manage.asgn-info-by-agent";
      this.headers["METHOD"] = "inqire";
      this.headers["ASYNC"] = false;
      this.headers["GRID_ID"] = "divGridAuthGroupNoAlloc";

      this.sendData = {};
      this.sendData["USER_ID"] = this.tab2_userId;
      this.sendData["USER_NM"] = this.tab2_userNm;
      this.sendData["USER_NICK"] = this.tab2_userNick;
      this.sendData["NULLABLE"] = "IS NULL";
      this.result();
    },
    getDivGridAuthGroupAllocCallback(response){
      let data = response.DATA;
      this.divGridAuthGroupAllocDataText = data;
      this.detailData = [];
    },
    getDivGridAuthGroupNoAllocCallback(response){
      let data = response.DATA;
      this.divGridAuthGroupNoAllocDataText = data;
      this.detailData = [];
    },
    moveRight_tab1() {

      let grid1_selected = this.selectedDivGridAuthUserNoAllocList;  // 미할당상담사 체크박스
      let grid1 = this.divGridAuthUserNoAllocDataText;     // 미할당상담사
      let grid2 = this.divGridAuthUserAllocDataText;       // 할당상담사

      if (this.selectedDivGridAuthUserNoAllocList.length < 1) {
        this.common_alert('미할당 상담사을 선택해주세요', 'error');
      }else{
        for(var i in grid1_selected){
          grid1.splice(grid1.indexOf(grid1_selected[i]) , 1);
          grid2.push(grid1_selected[i]);
        }

        this.selectedDivGridAuthUserNoAllocList = [];
      }

    },
    moveLeft_tab1(){
      let grid2_selected = this.selectedDivGridAuthUserAllocList;  // 할당상담사 사용 체크박스
      let grid1 = this.divGridAuthUserNoAllocDataText;     // 미할당그룹
      let grid2 = this.divGridAuthUserAllocDataText;       // 할당상담사

      if (this.selectedDivGridAuthUserAllocList.length < 1) {
        this.common_alert('할당 상담사을 선택해주세요', 'error');
      } else {
        for(var i in grid2_selected) {
          grid2.splice(grid2.indexOf(grid2_selected[i]), 1);
          grid1.push(grid2_selected[i]);
        }
        this.selectedDivGridAuthUserAllocList = [];
      }
    },

    authUserSave(){

      this.authUserDelete();
      // this.headers = this.initHeaders;
      // this.URLData = "/api/setting/system/author-by-agent-manage/agent-by-author-group/regist";
      // this.headers["SERVICE"] = "setting.system.author-by-agent-manage.agent-by-author-group";
      // this.headers["METHOD"] = "regist";
      // this.headers["ASYNC"] = false;
      // this.headers["REGIST"] = "TAB_1";

      // let grid1 = this.divGridAuthUserNoAllocDataText;       // 미할당상담사 그리드
      // let grid2 = this.divGridAuthUserAllocDataText;       // 할당상담사 그리드
      // let deletList = [];
      // let addList = [];

      // if (grid1.length > 0) {
      //   for(let i in grid1){

      //     let obj = {
      //       USER_ID: grid1[i].USER_ID,
      //       REG_DTTM: grid1[i].REG_DTTM,
      //       ATRT_GROUP_ID: grid1[i].ATRT_GROUP_ID,
      //       DATA_FLAG: "D",
      //       PROC_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
      //       REGR_DEPT_CD:"X",
      //       REGR_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
      //     };
      //     deletList.push(obj);
      //   }
      // }

      // if (grid2.length < 1) {

      //     let obj = {

      //        ATRT_GROUP_ID: this.atrtgroupId,
      //     };
      //     submitList.push(obj);
      
      // }else{

      //   for(let i in this.divGridAuthUserAllocDataText){
      //     let obj = {
      //       divGridAlloc_CHECKBOX: "0",
      //       USER_ID: this.divGridAuthUserAllocDataText[i].USER_ID,
      //       USER_NM: this.divGridAuthUserAllocDataText[i].USER_NM,
      //       USER_NICK: this.divGridAuthUserAllocDataText[i].USER_NICK,
      //       REG_DTTM: this.divGridAuthUserAllocDataText[i].REG_DTTM,
      //       ATRT_GROUP_ID: this.divGridAuthUserAllocDataText[i].ATRT_GROUP_ID,
      //       DATA_FLAG: "I",
      //       PROC_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
      //       REGR_DEPT_CD:"X",
      //       REGR_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
      //     };
      //     addList.push(obj);
      //   }
      // }

      //   this.sendData  = {
      //     deletList , addList
      //   }
      //   this.result();
    },

    async authUserDelete(){

      let grid1 = this.divGridAuthUserNoAllocDataText;       // 미할당상담사 그리드
      let deletList = [];
      
      if (grid1.length > 0) {
        for(let i in grid1){

          let obj = {
            USER_ID: grid1[i].USER_ID,
            ATRT_GROUP_ID: grid1[i].ATRT_GROUP_ID,
          };
          deletList.push(obj);
        }
      }


      let requestData = {
        headers: {
          SERVICE: "setting.system.author-by-agent-manage.agent-by-author-group",
          METHOD: this.initHeaders.METHOD,
          TYPE: this.initHeaders.TYPE,
        },
        sendData: {deletList}
      };

      requestData.headers["URL"] = "/api/setting/system/author-by-agent-manage/agent-by-author-group/regist";
      requestData.headers["METHOD"] = "delete";
      requestData.headers["ASYNC"] = false;
      requestData.headers["REGIST"] = "TAB_1";

      requestData.sendData["DATA_FLAG"] = "D";

      // requestData.sendData["STR_APLY_DT"] = this.dates[0].replace(/\-/g, "");
      // requestData.sendData["END_APLY_DT"] = this.dates[1].replace(/\-/g, "");
      // requestData.sendData["CUST_NO"] = this.INQRY_CUST_NO;
      // requestData.sendData["CUST_NM"] = this.INQRY_CUST_NM;
      // requestData.sendData["CSLT_ID"] = this.INQRY_CSLT_ID;
      // requestData.sendData["CSLT_NM"] = this.INQRY_CSLT_NM;
            
      const response = await this.common_postCall(requestData);

      if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {

          this.authUserAdd()
      }

    },

    async authUserAdd(){

      console.log("0000" + this.divGridAuthUserAllocDataText)

      let grid2 = this.divGridAuthUserAllocDataText;       // 할당상담사 그리드
      let addList = [];
      
      if (grid2.length > 0) {
        for(let i in grid2){

          let obj = {
            USER_ID: grid2[i].USER_ID,
            USER_NM: grid2[i].USER_NM,
            USER_NICK: grid2[i].USER_NICK,
            REG_DTTM: grid2[i].REG_DTTM,
            ATRT_GROUP_ID: grid2[i].ATRT_GROUP_ID,
            PROC_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
            REGR_DEPT_CD: this.$store.getters['userStore/GE_USER_ROLE'].USER_ATTR_A,
            REGR_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
            AMDR_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
            AMDR_DEPT_CD: this.$store.getters['userStore/GE_USER_ROLE'].USER_ATTR_A,
          };
          addList.push(obj);
        }
      }


      let requestData = {
        headers: {
          SERVICE: "setting.system.author-by-agent-manage.agent-by-author-group",
          METHOD: this.initHeaders.METHOD,
          TYPE: this.initHeaders.TYPE,
        },
        sendData: {addList}
      };

      requestData.headers["URL"] = "/api/setting/system/author-by-agent-manage/agent-by-author-group/regist";
      requestData.headers["METHOD"] = "delete";
      requestData.headers["ASYNC"] = false;
      requestData.headers["REGIST"] = "TAB_1";

      requestData.sendData["DATA_FLAG"] = "I";

      // requestData.sendData["STR_APLY_DT"] = this.dates[0].replace(/\-/g, "");
      // requestData.sendData["END_APLY_DT"] = this.dates[1].replace(/\-/g, "");
      // requestData.sendData["CUST_NO"] = this.INQRY_CUST_NO;
      // requestData.sendData["CUST_NM"] = this.INQRY_CUST_NM;
      // requestData.sendData["CSLT_ID"] = this.INQRY_CSLT_ID;
      // requestData.sendData["CSLT_NM"] = this.INQRY_CSLT_NM;
            
      const response = await this.common_postCall(requestData);

      if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
        
          this.moveRightSaveCallBack(response.data);
      }

    },


    moveRight_tab2() {
      let grid1_selected = this.selectedDivGridAuthGroupNoAllocList;  // 미할당 그룹 체크박스
      let grid1 = this.divGridAuthGroupNoAllocDataText;     // 미할당그룹
      let grid2 = this.divGridAuthGroupAllocDataText;       // 할당그룹
      if (grid1_selected.length == 1) {
        for(var i in grid1_selected){
          grid1.splice(grid1.indexOf(grid1_selected[i]) , 1);
          grid2.push(grid1_selected[i]);
        }
        this.selectedDivGridAuthGroupNoAllocList = [];
      } else if (this.selectedDivGridAuthGroupNoAllocList.length < 1) {
        this.common_alert('미할당 그룹을 선택해주세요', 'error');
      }  else {
        this.common_alert("미할당 그룹이 한개 이상 선택되었습니다 .","error");
      }
    },
    moveLeft_tab2(){
      let grid2_selected = this.selectedDivGridAuthGroupAllocList;  // 할당그룹 사용 체크박스
      let grid1 = this.divGridAuthGroupNoAllocDataText;     // 미할당그룹
      let grid2 = this.divGridAuthGroupAllocDataText;       // 할당그룹
      if (grid2_selected.length ==1 ) {
        for(var i in grid2_selected) {
          grid2.splice(grid2.indexOf(grid2_selected[i]), 1);
          grid1.push(grid2_selected[i]);
        }
        this.selectedDivGridAuthGroupAllocList = [];
      } else if (this.selectedDivGridAuthGroupAllocList.length < 1) {
        this.common_alert('할당 그룹을 선택해주세요', 'error');
      } else {
        this.common_alert("할당 그룹이 한개 이상 선택되었습니다.","error");
      }
    },
    submitGroupConfirm(){//저장`
      if(this.divGridAuthGroupAllocDataText.length > 1){
        this.common_alert('할당 그룹은 한개 이상은 저장 할수 없습니다 .', 'error')
        return;
      }
      this.common_confirm('저장 하시겠습니까?', this.submitGroupGrid, null, null, null, 'confirm');
    },

    submitGroupGrid(){//상담사별 권한관리 저장
      this.headers = this.initHeaders;
      this.URLData = "/api/setting/system/author-by-agent-manage/author-group-by-agent/regist";
      this.headers["SERVICE"] = "setting.system.author-by-agent-manage.author-group-by-agent";
      this.headers["METHOD"] = "regist";
      this.headers["ASYNC"] = false;
      this.headers["REGIST"] = "TAB_2";
      let submitList = [];
      let headersObj = {
        DATA_FLAG:"D",
        USER_ID:this.tab2_userId,
      }
      submitList.push(headersObj);
      for(let i in this.divGridAuthGroupAllocDataText){
        let obj = {
          divGridAlloc_CHECKBOX:'0',
          ATRT_GROUP_NM: this.divGridAuthGroupAllocDataText[i].ATRT_GROUP_NM,
          GROUP_XPLN: this.divGridAuthGroupAllocDataText[i].GROUP_XPLN,
          MNGR_YN: this.divGridAuthGroupAllocDataText[i].MNGR_YN,
          ETC_INFO01: this.divGridAuthGroupAllocDataText[i].ETC_INFO01,
          ETC_INFO02_NM: this.divGridAuthGroupAllocDataText[i].ETC_INFO02_NM,
          ETC_INFO03: this.divGridAuthGroupAllocDataText[i].ETC_INFO03,
          ATRT_GROUP_ID: this.divGridAuthGroupAllocDataText[i].ATRT_GROUP_ID,
          USER_ID:this.tab2_userId,
          ETC_INFO02: this.divGridAuthGroupAllocDataText[i].ETC_INFO02,
          DATA_FLAG:"I",
          PROC_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          REGR_DEPT_CD: this.$store.getters['userStore/GE_USER_ROLE'].USER_ATTR_A,
          REGR_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        };
        submitList.push(obj);
      }
      this.sendData  = {
        list:submitList
      }
      this.result();
    },
    submitGroupGridCallBack(response){//
      this.common_alert("정상처리 되었습니다.","done");
      this.getGroupList2();
    },
    closeAlert(){//
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },
    getGroupList(){// 권한그룹별 상담사설정 tab
      if(this.mixin_isEmpty(this.atrtgroupId)) {
        this.common_alert('권한그룹을 선택해주세요.', 'error');
        return false;
      }

      this.getDivGridAuthUserNoAlloc(); //미할당상담사
      this.getDivGridAuthUserAllocList(); //할당상담사
    },
    getDivGridAuthUserNoAlloc(){
      this.headers = this.initHeaders;
      this.URLData = "/api/setting/system/author-by-agent-manage/asgn-info-by-agent/inqire";
      this.headers["SERVICE"] = "setting.system.author-by-agent-manage.asgn-info-by-agent";
      this.headers["METHOD"] = "inqire";
      this.headers["ASYNC"] = false;
      this.headers["GRID_ID"] = "divGridAuthUserNoAlloc";
      this.headers["ROW_CNT"] = "999";
      this.headers["PAGES_CNT"] = "1";

      this.sendData= {};
      this.sendData["ATRT_GROUP_ID"] = this.atrtgroupId;
      this.sendData["USER_ID"] = this.tab_userId;;
      this.sendData["USER_NM"] = this.userNm;
      this.sendData["USER_NICK"] = this.userNick;
      this.sendData["NULLABLE"] = "IS NULL";
      this.divGridAuthUserNoAllocPage = 1;
      this.divGridAuthUserNoAllocPageCount = 0;
      this.divGridAuthUserNoAllocItemsPerPage = 100;
      this.divGridAuthUserNoAllocTotalVisible = 10;
      this.divGridAuthUserNoAllocDataText = [];
      this.result();
    },
    getDivGridAuthUserAllocList(){
      this.URLData = "/api/setting/system/author-by-agent-manage/asgn-info-by-agent/inqire";
      this.headers = this.initHeaders;
      this.headers["SERVICE"] = "setting.system.author-by-agent-manage.asgn-info-by-agent";
      this.headers["METHOD"] = "inqire";
      this.headers["ASYNC"] = false;
      this.headers["GRID_ID"] = "divGridAuthUserAlloc";

      this.sendData= {};
      this.sendData["USER_NICK"] = this.userNick;
      this.sendData["USER_NM"] = this.userNm;
      this.sendData["USER_ID"] = this.tab_userId;
      this.sendData["ATRT_GROUP_ID"] = this.atrtgroupId;
      this.result();
    },
    getDivGridAuthUserNoAllocCallBack(response) {
      let data = response.DATA;
      this.divGridAuthUserNoAllocDataText = data;
      // 그리드 데이터 가공
      let idx = 1;
      let dataText = this.divGridAuthUserNoAllocDataText;
      for (let i in dataText) {
        this.divGridAuthUserNoAllocDataText[i]["index"] = idx++;
      }
      this.selectedDivGridAuthUserNoAllocList =[];
      this.detailData = [];
    },
    getDivGridAuthUserAllocCallBack(response){
      let data = response.DATA;
      this.divGridAuthUserAllocDataText = data;
      let idx = 1;
      let dataText = this.divGridAuthUserAllocDataText;
      for (let i in dataText) {
        this.divGridAuthUserAllocDataText[i]["index"] = idx++;
      }
      this.selectedDivGridAuthUserAllocList =[];
      this.detailData = [];
    },
    moveLeftDeleteConfirm() {//권한그룹별 상담사설정 저장
      if (this.selectedDivGridAuthUserAllocList.length < 1) {
        this.common_alert('할당상담사를 선택해주세요', 'error');
      } else{
        this.common_confirm('선택한상담사를 미할당 처리 하시겠습니까?', this.moveLeftDelete, null, null, null, 'confirm');
      }
    },
    moveLeftDelete(){
      this.headers = this.initHeaders;
      this.URLData = "/api/setting/system/author-by-agent-manage/agent-by-author-group/delete";
      this.headers["SERVICE"] = "setting.system.author-by-agent-manage.agent-by-author-group";
      this.headers["METHOD"] = "delete";
      this.headers["ASYNC"] = false;
      let grid1_selected = this.selectedDivGridAuthUserNoAllocList;  // 미할당 사용 체크박스
      let grid2_selected = this.selectedDivGridAuthUserAllocList;   // 할당 사용 체크박스
      let grid1 = this.divGridAuthUserNoAllocDataText;     // 미할당상담사 그리드
      let grid2 = this.divGridAuthUserAllocDataText;       // 할당상담사 그리드
      let submitDeleteList = [];
      if (grid2_selected.length > 0) {
        for(var i in grid2_selected) {
          grid2.splice(grid2.indexOf(grid2_selected[i]), 1);
          grid1.push(submitDeleteList[i]);
        }
        for(let i in this.selectedDivGridAuthUserAllocList){
          let obj = {
            divGridAuthUserAlloc_CHECKBOX: "1",
            USER_ID: this.selectedDivGridAuthUserAllocList[i].USER_ID,
            USER_NM: this.selectedDivGridAuthUserAllocList[i].USER_NM,
            USER_NICK: this.selectedDivGridAuthUserAllocList[i].USER_NICK,
            REG_DTTM: this.selectedDivGridAuthUserAllocList[i].REG_DTTM,
            ATRT_GROUP_ID: this.selectedDivGridAuthUserAllocList[i].ATRT_GROUP_ID,
            DATA_FLAG: "N",
          };
          submitDeleteList.push(obj);
        }
        this.sendData  = {
          list:submitDeleteList
        }
        this.result();
      }
    },
    moveLeftDeleteCallBack(response){//
      this.common_alert("정상처리 되었습니다.","done");
      this.getGroupList();
    },
    moveRightSaveConfirm(){//권한그룹별 상담사설정 저장
      if(this.selectedDivGridAuthUserNoAllocList.length < 1) {
        this.common_alert('미할당상담사를 선택해주세요', 'error');
      }else {
        this.common_confirm('선택한상담사를 할당 처리 하시겠습니까?', this.moveRightSave, null, null, null, 'confirm');
      }
    },
    moveRightSave(){//저장
      this.headers = this.initHeaders;
      this.URLData = "/api/setting/system/author-by-agent-manage/agent-by-author-group/regist";
      this.headers["SERVICE"] = "setting.system.author-by-agent-manage.agent-by-author-group";
      this.headers["METHOD"] = "regist";
      this.headers["ASYNC"] = false;
      this.headers["REGIST"] = "TAB_1";
      let grid1_selected = this.selectedDivGridAuthUserNoAllocList;  // 미할당 사용 체크박스
      let grid2_selected = this.selectedDivGridAuthUserAllocList;   // 할당 사용 체크박스
      let grid1 = this.divGridAuthUserNoAllocDataText;     // 미할당상담사 그리드
      let grid2 = this.divGridAuthUserAllocDataText;       // 할당상담사 그리드
      let submitList = [];
      if (grid1_selected.length > 0) {
        for(var i in grid1_selected) {
          grid1.splice(grid1.indexOf(grid1_selected[i]), 1);
          grid2.push(submitList[i]);
        }
        for(let i in this.selectedDivGridAuthUserNoAllocList){
          let obj = {
            divGridAlloc_CHECKBOX: "0",
            USER_ID: this.selectedDivGridAuthUserNoAllocList[i].USER_ID,
            USER_NM: this.selectedDivGridAuthUserNoAllocList[i].USER_NM,
            USER_NICK: this.selectedDivGridAuthUserNoAllocList[i].USER_NICK,
            REG_DTTM: this.selectedDivGridAuthUserNoAllocList[i].REG_DTTM,
            ATRT_GROUP_ID: this.selectedDivGridAuthUserNoAllocList[i].ATRT_GROUP_ID,
            DATA_FLAG: "I",
            PROC_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
            REGR_DEPT_CD:"X",
            REGR_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          };
          submitList.push(obj);
        }
        this.sendData  = {
          list:submitList
        }
        this.result();
      }
    },
    moveRightSaveCallBack(response){//
      this.common_alert("정상처리 되었습니다.","done");
      this.getGroupList();
    },
    async result(){
      console.log("im here1");
      let head='';
      let dataa='';
      //결과값을 보여주는 부분 -> 실제 사용 시 dataa의 value를 사용
      //api 호출 부분
      await api.post(this.URLData,   //api url입력
          //await axios.post('https://local.hkpalette.com:8443/'+this.URLData,{
          this.sendData
          , //api에서 사용될 data 입력
          {head: this.headers
          }) //api에 로그를 남기기위한 값입력 (필수) (SERVICE, METHOD, TYPE)
          .then((response) => {
            console.log(response);
            // head = JSON.stringify(response.data.HEADER);
            //dataa = JSON.stringify(response.data.DATA);
            if(typeof(response.data) === "string"){
              let responseData = JSON.parse(response.data.replaceAll("\n", ""));
              this.$store.commit("alertStore/openAlertDialog", {
                alertDialogToggle: true,
                msg: responseData.HEADER.ERROR_MSG,
                iconClass: "svg-error-lg",
                type: "default",
              });
              return;
            }
            if(response.data.HEADER.METHOD == "inqire" &&response.data.HEADER.TWB_SQL_ID =="selectRtnNotAuthAllocAll"){
              this.getDivGridAuthUserNoAllocCallBack(response.data);
            }else if(response.data.HEADER.METHOD == "inqire" &&response.data.HEADER.TWB_SQL_ID =="selectRtnAuthAllocAll"){
              this.getDivGridAuthUserAllocCallBack(response.data);
            }else if(response.data.HEADER.METHOD == "inqire"&&response.data.HEADER.GRID_ID =="divGridAuthGroupNoAlloc"){
              this.getDivGridAuthGroupNoAllocCallback(response.data);
            }else if(response.data.HEADER.METHOD == "inqire"&&response.data.HEADER.GRID_ID =="divGridAuthGroupAlloc"){
              this.getDivGridAuthGroupAllocCallback(response.data);
            }else if(response.data.HEADER.METHOD == "regist"&&response.data.HEADER.REGIST =="TAB_2") {
              this.submitGroupGridCallBack(response.data);
            }else if(response.data.HEADER.METHOD == "regist"&&response.data.HEADER.REGIST =="TAB_1") {
              this.moveRightSaveCallBack(response.data);
            }else if(response.data.HEADER.METHOD == "delete") {
              this.moveLeftDeleteCallBack(response.data);
            }else if(response.data.HEADER.METHOD == "inqire"&&response.data.HEADER.SERVICE =="setting.system.menu-author-manage.author-group-code") {
              this.groupCodeInqireCallBack(response.data);
            }
          }) //api 호출 성공 이후 수행
          .catch((err) => {
            this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.", "error");
          }) //api호출 에러 작업 수행

      this.HEADERResult = head;
      this.DATAResult = dataa;
    }
  },
  mounted() {
    this.dropItems2 = this.$store.getters['userStore/GE_USER_COMPANY'];
    this.groupCodeInqire();
  },
  computed: {
    dateRangeText() {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + " ~ " + newEndDate;
    },
    initHeaders(){
      return this.headers = {
        "SERVICE" : "",
        "METHOD" : "",
        "TWB_SQL_ID":"",
        "TYPE" : this.HEADER_TYPE,
      };
    },
  },
  created() { 
    this.dataTableOptions.headers = this.divGridAuthUserNoAlloc;
    this.dataTableOptions.items = this.divGridAuthUserNoAllocDataText;
    this.dataTableOptions2.headers = this.divGridAuthUserAlloc;
    this.dataTableOptions2.items = this.divGridAuthUserAllocDataText;
    this.dataTableOptions3.headers = this.divGridAuthGroupNoAlloc;
    this.dataTableOptions3.items = this.divGridAuthGroupNoAllocDataText;
    this.dataTableOptions4.headers = this.divGridAuthGroupAlloc;
    this.dataTableOptions4.items = this.divGridAuthGroupAllocDataText;
  }
};
</script>
<style>

</style>
